import { createReduxModule } from "hooks-for-redux";
import db, { DraftTweet, DocTypes } from "./pouch";

export const TWITTER_CHR_LENGTH = 280;

export const SPLIT_SENTINEL = escapeRegExp("---");
export const INLINE_SPLIT_SENTINEL = escapeRegExp("↩");

export const URL_REGEXP = /https?:\/\/[^\s$.?#-].[^\s]*/g;

export enum ThreadMarkerTypes {
  TrailingFraction = "1/n (trailing)",
  TrailingEllipses = "... (trailing)",
  LeadingFraction = "1/n (leading)",
  LeadingEllipses = "... (leading)",
  None = "",
}

export interface ThreadMarker {
  type: ThreadMarkerTypes;
  start: string;
  label?: string;
  regexp: RegExp;
  remove?: Function;
  end: string;
}

export const ThreadMarkers: { [threadMarkerType: string]: ThreadMarker } = {
  [ThreadMarkerTypes.LeadingFraction]: {
    type: ThreadMarkerTypes.LeadingFraction,
    start: "i/n",
    label: String(ThreadMarkerTypes.LeadingFraction),
    regexp: new RegExp(`^[0-9]+/[0-9]+ `),
    remove: (t: string) => t.replace(/^[0-9]+\/[0-9]+ /, ""),
    end: "",
  },
  // [ThreadMarkerTypes.LeadingEllipses]: {
  //   type: ThreadMarkerTypes.LeadingEllipses,
  //   start: "...",
  //   end: "",
  // },
  // [ThreadMarkerTypes.TrailingFraction]: {
  //   type: ThreadMarkerTypes.TrailingFraction,
  //   start: "",
  //   end: "i/n",
  // },
  // [ThreadMarkerTypes.TrailingEllipses]: {
  //   type: ThreadMarkerTypes.TrailingEllipses,
  //   start: "",
  //   end: "...",
  // },
  [ThreadMarkerTypes.None]: {
    type: ThreadMarkerTypes.None,
    start: "",
    regexp: /^/, // identity
    end: "",
  },
};

export const isSplitDelimRegExp = new RegExp(
  `${INLINE_SPLIT_SENTINEL}|${SPLIT_SENTINEL}`,
  "g",
);

/**
 *  Returns the value of a single key of document.cookie.
 *  (adapted from https://stackoverflow.com/a/25490531)
 **/
export function readCookieValue(key: string): string {
  const cookies = document.cookie.match(
    `(^|;)\\s*${escapeRegExp(key)}\\s*=\\s*([^;]+)`,
  );
  return cookies ? cookies.pop() : "";
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

/**
 * Ensures all instances of SPLIT_SENTINEL in `str` are
 * converted to INLINE_SPLIT_SENTINEL
 **/
export function normalizeSentinels(str: string) {
  return replaceAll(str, SPLIT_SENTINEL, INLINE_SPLIT_SENTINEL);
}

export function replaceAll(
  string: string,
  toReplace: string | RegExp,
  replacement: string,
) {
  return string.split(toReplace).join(replacement);
}

export function previewContentsToEditorContents(
  previewContents: string[],
): string {
  /* spec: Any INLINE_SPLIT_SENTINEL instance should end up with
     one `INLINE_SPLIT_SENTINEL\n\n`, trimming any spaces before the next line */
  let previewText = normalizeSentinels(previewContents.join(" "));

  return replaceAll(
    // first make sure all INLINE_SPLIT_SENTINELs have trailing \n\n
    replaceAll(
      previewText,
      INLINE_SPLIT_SENTINEL,
      `${INLINE_SPLIT_SENTINEL}\n\n`,
    ),
    // then make sure none of them have more than 2 trailing \n
    new RegExp(`${INLINE_SPLIT_SENTINEL}[\n ]+`, "g"),
    `${INLINE_SPLIT_SENTINEL}\n\n`,
  );
}

export function editorContentsToPreviewContents(
  editorContents: string,
  threadMarkerType?: ThreadMarkerTypes,
): string[] {
  let splittened: string[] = splitText(editorContents).filter((x) => x !== "");
  splittened = splittened
    .map(normalizeSentinels)
    .map((tweet, idx) => {
      // clean up spacing issues at beginning of lines following an
      // artificially inserted line break
      let prevTweet: string = splittened[idx - 1] ? splittened[idx - 1] : "";
      let prevTweetEndsWithSplitMarker =
        prevTweet[prevTweet.length - INLINE_SPLIT_SENTINEL.length] ===
          INLINE_SPLIT_SENTINEL;

      if (prevTweetEndsWithSplitMarker || idx === 0) {
        tweet = tweet.replace(/^[ \n]+/g, "");
      }

      return tweet;
    });

  return splittened;
}

/** like String.prototype.split(), but leaves the delimiter in place **/
export function inclusiveSplit(
  contents: string,
  delim = INLINE_SPLIT_SENTINEL,
) {
  let tempSentinel = "---===X-X===---";
  return replaceAll(
    contents,
    delim,
    delim + tempSentinel,
  ).split(tempSentinel);
}

export function insertThreadMarker(
  idx: number,
  previewContents: string[],
  threadMarkerType: ThreadMarkerTypes,
): string {
  let tweet: string = previewContents[idx];

  if (!tweet) {
    return "";
  }

  if (!threadMarkerType) {
    return tweet;
  }
  return `${idx + 1}/${previewContents.length} ${tweet}`;
}

export function removeThreadMarker(
  threadMarkerType: ThreadMarkerTypes,
  text: string,
): string {
  if (!ThreadMarkers[threadMarkerType]?.remove) return text;
  return ThreadMarkers[threadMarkerType]?.remove(text);
}

export function splitText(
  normalizedText: string,
  splitLen: number = TWITTER_CHR_LENGTH,
): string[] {
  let byWord = normalizedText.split(/(\s+)/g);

  let output: string[] = [];

  if (!normalizedText) return output;

  let cum = "";
  for (let i = 0; i < byWord.length; i++) {
    let word = byWord[i];

    if (word.trim() === "") continue;

    let padding = byWord[i + 1] || "";
    let nextWord = byWord[i + 2] || "";

    if (!nextWord) {
      output.push(`${cum}${word}`);
      break;
    }

    if (isSplitDelimRegExp.test(word)) {
      let [left, right] = word.split(isSplitDelimRegExp);
      output.push(`${cum}${left}${INLINE_SPLIT_SENTINEL}`);
      cum = `${right}`;

      // account for the \n\n we append after the split sentinel
      if (padding !== "\n\n") cum += padding;

      continue;
    }

    if (cum.length + word.length >= splitLen - 1) {
      // don't leave a space on the end of a tweet
      output.push(cum.replace(/\s$/, ""));
      cum = word + padding;
    } else {
      cum += word + padding;
    }
  }

  // console.log("> Splittext input: \n", text, "\n> output: \n", output);
  // make absolutely sure all INLINE_SPLIT_SENTINELs are split in preview output
  return output;
}

let initialText = "";
const latestDraftDocId = "latestdraft";

const getInitialText = async () => {
  let doc = await db.get<DraftTweet>(latestDraftDocId);
  initialText = doc.text;
  setEditorContents(initialText);
  setPreviewContents(editorContentsToPreviewContents(initialText));
  return initialText;
};

const storeInitialText = async (text: string) => {
  let doc: DraftTweet;
  try {
    doc = await db.get<DraftTweet>(latestDraftDocId);
  } catch (e) {
    doc = {
      _id: latestDraftDocId,
      type: DocTypes.DraftTweet,
      text: "",
    };
  }
  doc.text = text;
  return db.put(doc);
};

export interface nextCursorPos {
  idx: number; // active tweet idx
  pos: number; // cursor pos within tweetbox
}

export enum BirdsOrBears {
  Birds = "🐦",
  Bears = "🐻",
  Blank = "",
}

getInitialText();

export const [useEditorContents, { setEditorContents }] = createReduxModule(
  "EditorContents",
  "",
  {
    setEditorContents: (_, newContents) => {
      storeInitialText(newContents);
      return newContents;
    },
  },
);
export const [usePreviewContents, setPreviewContents] = createReduxModule(
  "PreviewContents",
  editorContentsToPreviewContents(initialText),
);
export const [useThreadStyle, setThreadStyle, threadStyleStore] =
  createReduxModule(
    "ThreadStyle",
    // ThreadMarkerTypes.LeadingEllipses,
    ThreadMarkerTypes.None,
  );
export const [useNewLineIndicator, setNewLineIndicator] = createReduxModule(
  "NewLineIndicator",
  SPLIT_SENTINEL,
);
export const [useActiveTweetBox, setActiveTweetBox] = createReduxModule(
  "ActiveTweetBox",
  null,
);
export const [useNextCursorPos, setNextCursorPos] = createReduxModule(
  "NextCursorPos",
  {} as nextCursorPos,
);
export const [useBirdsOrBears, setBirdsOrBears] = createReduxModule(
  "BirdsOrBears",
  BirdsOrBears.Birds,
);
