import PouchDB from "pouchdb";

export const userdataDb = new PouchDB("userdata");

export enum DocTypes {
  DraftTweet = "DraftTweet",
}

export interface DraftTweet {
  _id: string;
  type: DocTypes;
  text: string;
}

// DEBUG ONLY
// @ts-ignore
window.pouch = userdataDb;

export default userdataDb;
