import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { readCookieValue } from "../store";
import {
  twitterBlue,
  borderGray,
} from "../components/colors";
import { apiUrl } from "../api";
import Avatar from "react-avatar";

// TODO: Don't use camelcase keys (fix on the the golang side)
interface ThreadBearServerConfig {
  ScreenName: string;
}

const threadBearConfigCookieKey = "threadBearConfig";

// redirects to API, which will then do the right thing™
const signInUrl = `${apiUrl}/authorize`;
const logoutUrl = `${apiUrl}/logout`;

export const UserProfileBox = ({}) => {
  // const [logOutDropDownVisible, setLogOutDropDownVisible] = useState(false);
  const [logOutDropDownVisible, setLogOutDropDownVisible] = useState(false);

  let config: ThreadBearServerConfig;
  try {
    let cookieVal = decodeURIComponent(
      readCookieValue(threadBearConfigCookieKey),
    );
    config = JSON.parse(cookieVal);
  } catch (e) {}

  let screenName: string;
  if (config) {
    screenName = config.ScreenName;
  }

  // TODO: cache the avatar or otherwise get it to not flicker on re-render. (memo?)
  const SignedInAvatar = () =>
    <AvatarWrapper
      onClick={() => setLogOutDropDownVisible(true)}
    >
      <Avatar
        twitterHandle={screenName}
        name={screenName}
        color={twitterBlue}
        round={true}
        size="19"
      />
      <LogoutContainer
        dropDownVisible={logOutDropDownVisible}
        setLogOutDropDownVisible={setLogOutDropDownVisible}
      >
        <ScreenName>@{screenName}</ScreenName>
        <a href={logoutUrl}>
          Log Out
        </a>
      </LogoutContainer>
    </AvatarWrapper>;

  const SignInLink = () =>
    <LoginButton>
      <a href={signInUrl}>Sign In</a>
    </LoginButton>;

  return (<OuterContainer>
    {screenName ? <SignedInAvatar /> : <SignInLink />}
  </OuterContainer>);
};

const AvatarWrapper = styled.div`
  display: inline-block;
  box-sizing: border-box;
`;

const Toolbar = styled.div`
  font-size: 8px;

  width: 100%;
  color: ${twitterBlue};
  text-align: right;
  box-sizing: border-box;

  > svg {
    padding-right: 5px;
  }

`;

interface LogoutContainerProps {
  dropDownVisible: boolean;
  setLogOutDropDownVisible: Function;
  children: any;
}

const LogoutContainer = (props: LogoutContainerProps) => {
  const { dropDownVisible, setLogOutDropDownVisible } = props;
  // the <HTMLDivElement> is important for satisfying typescript!
  const nodeRef: React.MutableRefObject<HTMLDivElement> = useRef();

  // modal-like behavior adapted from:
  // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
  // (codesandbox link:
  // https://codesandbox.io/s/9o3lw5792w?file=/src/Dropdown.js:0-138)

  const hideDropdown = (e) => {
    if (nodeRef.current?.contains(e.target)) {
      return;
    }
    setLogOutDropDownVisible(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", hideDropdown);
    return () => document.removeEventListener("mousedown", hideDropdown);
  }, []);

  return props.dropDownVisible &&
    <LogoutContainerStyles ref={nodeRef} {...props} />;
};

const LogoutContainerStyles = styled.div<LogoutContainerProps>`
  position: absolute;
  top: 30px;
  right: 5px;
  background-color: white;
  text-align: center;
  display: ${({ dropDownVisible: v }) => v ? "inline-block" : "none"};
  transition: .5s;
  opacity: ${({ dropDownVisible: v }) => v ? "1" : "0"};
  border: 1px solid ${borderGray};

  font-size: 10px;
  border-radius: 5px;

  padding: 5px 0;

  &:focus {
    outline: none;
  }

  a, a:visited {
    text-decoration: none;
    color: ${twitterBlue};
  }`;

const ScreenName = styled.div`
  padding: 2px 5px;
  font-size: 8px;
  height: 10px;
  margin-bottom: 2px;
  box-sizing: border-box;
`;

const LoginButton = styled.div`
  a, a:visited {
    text-decoration: none;
    color: ${twitterBlue};
  }
`;

const OuterContainer = styled.div`
  height: 30px;
  z-index: 5; /* this probably shouldn't be needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default UserProfileBox;
