import ky from "ky";

// const baseURL = process.env.REACT_APP_API
export const apiUrl = process.env.REACT_APP_API ??
  "http://localhost:5000";

export class ThreadBearClient {
  constructor() {
  }

  async postTweets(tweets: string[]) {
    const url = `${apiUrl}/tweet`;
    return ky.post(url, { credentials: "include", json: tweets }).json();
  }
}

const defaultClient = new ThreadBearClient();
export default defaultClient;
