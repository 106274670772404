import React, { useRef, useState, useEffect } from "react";
import { CharacterCount } from "./CharacterCounter";
import TextareaAutosize from "react-textarea-autosize";
import {
  insertThreadMarker,
  previewContentsToEditorContents,
  editorContentsToPreviewContents,
  setEditorContents,
  setPreviewContents,
  usePreviewContents,
  useThreadStyle,
  useActiveTweetBox,
  useBirdsOrBears,
  setActiveTweetBox,
  useNextCursorPos,
  setNextCursorPos,
  INLINE_SPLIT_SENTINEL,
  removeThreadMarker,
  normalizeSentinels,
  inclusiveSplit,
} from "../store";
import styled from "styled-components";
import { twitterBlue, borderGray } from "./colors";
import { flatten, last } from "lodash";

export const TwitterTextBox = ({ text, placeholder = "", idx = 0 }) => {
  const previewContents = usePreviewContents();
  const birdsOrBears = useBirdsOrBears();
  const threadStyle = useThreadStyle();
  // TODO: get rid of activeTweetBox and just use nextCursorPos
  const activeTweetBox = useActiveTweetBox();
  const nextCursorPos = useNextCursorPos();

  let inputRef = useRef(null);

  if (threadStyle) {
    text = insertThreadMarker(
      idx,
      previewContents,
      threadStyle,
    );
  }

  const [cursorPos, setCursorPos] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      // cursor pos of 0 is 'special', means we don't try to move the cursor at all
      if (cursorPos !== 0) {
        // console.log("trying to reset tweet cursor to ", cursorPos);

        let textWithThreadMarkers = insertThreadMarker(
          idx,
          previewContents,
          threadStyle,
        );

        let destCursorPos = cursorPos;

        // account for cursor landing after the ↩
        if (textWithThreadMarkers[cursorPos - 1] === INLINE_SPLIT_SENTINEL) {
          destCursorPos -= 1;
        }
        inputRef.current.selectionStart = destCursorPos;
        inputRef.current.selectionEnd = destCursorPos;

        setCursorPos(0);
      }
    }

    if (nextCursorPos?.idx === idx) {
      // console.log("setting from nextcursorpos");
      inputRef.current.selectionStart = nextCursorPos.pos;
      inputRef.current.selectionEnd = nextCursorPos.pos;
      setNextCursorPos(null);
    }

    // focus this tweetbox if activeTweetBox is set
    if (activeTweetBox === idx && previewContents.length > 0) {
      // console.log("Attempting to focus ", activeTweetBox);
      inputRef?.current?.focus();
      setActiveTweetBox(null);
    }
  }, [cursorPos, activeTweetBox]);

  function onPreviewTextChange(ev) {
    let char = ev.nativeEvent.data;

    let changedContents = ev.target.value;
    let normalizedTweetText = normalizeSentinels(changedContents);

    let splitChangedContents = inclusiveSplit(
      normalizedTweetText,
      INLINE_SPLIT_SENTINEL,
    );

    // store the current position so we can restore it on next render
    // TODO: (?) get fancier and do git-like contextual parsing so that we can set the cursorPos
    //       based on what *letters* it's near, not just it's position in the str
    if (previewContents.length > 0) {
      // console.log("Updating cursor pos");
      setCursorPos(inputRef.current.selectionStart);
    }
    let newPreviewContents: string[] = flatten(
      [
        ...previewContents.slice(0, idx),
        ...splitChangedContents,
        ...previewContents.slice(idx + 1),
      ]
        .map(removeThreadMarker.bind(this, threadStyle))
        .map(normalizeSentinels)
        .map((x) => inclusiveSplit(x, INLINE_SPLIT_SENTINEL)),
    );

    let newEditorContents = previewContentsToEditorContents(newPreviewContents);

    setEditorContents(newEditorContents);

    let finalPreviewContents = editorContentsToPreviewContents(
      newEditorContents,
    );
    if (char === " ") {
      finalPreviewContents[idx] = removeThreadMarker(
        threadStyle,
        splitChangedContents[0],
      );
    }

    setPreviewContents(finalPreviewContents);
    if (
      // there is already data present
      previewContents.length > 0 &&
      // a new tweet was inserted
      previewContents.length < finalPreviewContents.length &&
      // and user moved cursor (but did not select a range)
      inputRef.current.selectionStart === inputRef.current.selectionEnd &&
      inputRef.current.selectionStart >= normalizedTweetText.length
    ) {
      // console.log("🔖 Jumping to next tweet");
      setNextCursorPos({ idx: idx + 1, pos: 9999 });
      setActiveTweetBox(idx + 1);
      // setNextCursorPos({idx: idx+1, pos: previewContents[idx+1].length})
    }
  }

  function onSelectionChange(ev) {
    // jump to next tweet box if cursor moves behind the ↩
    let start = ev.target.selectionStart,
      end = ev.target.selectionEnd,
      value = ev.target.value;
    if (start !== end) return;
    if (
      end >= value.length &&
      last(normalizeSentinels(ev.target.value)) === INLINE_SPLIT_SENTINEL
    ) {
      // console.log(
      //   "At the end!, of '%s' last char is '%'. want to go from idx %s to %s",
      //   ev.target.value,
      //   last(normalizeSentinels(ev.target.value)),
      //   idx,
      //   idx + 1,
      // );

      setActiveTweetBox(idx + 1);
      setNextCursorPos({ idx: idx + 1, pos: 0 });
    }
  }

  return (
    <OuterWrapper>
      <CloseButton>{birdsOrBears}</CloseButton>
      <ColumnContainer>
        <Spacer width="50px" />
        <EditorWrapper>
          <div>
            <TextInput
              value={text}
              ref={inputRef}
              placeholder={placeholder}
              onFocus={setActiveTweetBox.bind(this, idx)}
              onBlur={(...args) => {
                onPreviewTextChange(...args);
                setActiveTweetBox(null);
              }}
              onSelect={onSelectionChange}
              onChange={onPreviewTextChange}
            />
          </div>
          <BottomRow>
            <TwitterButtons>
              <img alt="buttons" src="twitter-buttons.png" />
            </TwitterButtons>
            <Spacer width="100%" />
            <CharacterCount text={text} />
            {// <Divider />
            // <PlusSign />
            }
          </BottomRow>
        </EditorWrapper>
      </ColumnContainer>
    </OuterWrapper>
  );
};

const TextInput = styled(TextareaAutosize)`
  width: 100%;
  height: 100%;
  border: 1px solid red;
  font-size: 13pt;

  /* min-height: 50px; */
  font-family: sans-serif;
  overflow: visible;
  resize: none;
  border: 0;
  box-sizing: border-box;
  &:focus { 
    border: 0;
    outline: none;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  background-color: white;
  width: 95%;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${borderGray};
  text-align: center;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
`;

const CloseButton = styled.div`
  color: ${twitterBlue};
  text-align: left;
  width: 100%;
  border-bottom: 1px solid ${borderGray};
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
`;

const EditorWrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 10px;
  padding-bottom: 0;
  box-sizing: border-box;
`;

const BottomRow = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 40px;
  margin: 5px;
`;

const TwitterButtons = styled.div`
  display: none;
  text-align: left;
  img {
    height: 30px;
  }
`;

const Spacer = styled.div<any>`
  width: ${({ width }) => width};
`;

// const Divider = styled.div`
//   border-right: 1px solid ${borderGray};
//   margin-left: 10px;
//   margin-right: 10px;
//   height: 30px;
// `;
//
// const StyledPlusSign = styled.div`
//   width: 30px;
//   height: 30px;
//   flex-shrink: 0;
//   border: 1px solid ${twitterBlue};
//   border-radius: 15px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//
//   span {
//     color: ${twitterBlue};
//     font-size: 30px;
//     /* background-color: green; */
//     /* color: white; */
//     font-family: monospace;
//   }
// }
// `;
// const PlusSign = () => (<StyledPlusSign><span>+</span></StyledPlusSign>);
