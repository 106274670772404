import React from "react";
import styled from "styled-components";
import { twitterBlue } from "./colors";
import { replaceAll, URL_REGEXP, TWITTER_CHR_LENGTH } from "../store";
import { repeat } from "lodash";

interface CharacterProps {
  text: string;
}

// in a tweet, all urls only take TWITTER_URL_LEN chrs of your chr count
const TWITTER_URL_LEN: number = 23;

const remaining = (count: number) => TWITTER_CHR_LENGTH - count;

const countCharacters = (text: string) => {
  const urlPlaceholder = repeat("_", TWITTER_URL_LEN);
  const replaced = replaceAll(text, URL_REGEXP, urlPlaceholder);
  return replaced.length;
};

export const CharacterCount = (
  { text },
) => (<StyledCharacterCountDiv text={text}>
  <InnerNum>{remaining(countCharacters(text))}</InnerNum>
</StyledCharacterCountDiv>);

const StyledCharacterCountDiv = styled.span<CharacterProps>`
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid ${twitterBlue};
  border-radius: 50%; 

  ${({ text }) => {
  if (remaining(countCharacters(text)) < 0) {
    return `
      border-color: red;`;
  } else if (remaining(countCharacters(text)) < 20) {
    return `
      border-color: orange;`;
  } else if (remaining(countCharacters(text)) < 40) {
    return `
      border-color: yellow;
    `;
  }
}}
`;

const InnerNum = styled.span`
  display: block;
  color: ${twitterBlue};
  ${({ children: remainingChrs }) => {
  if (remainingChrs < 0) return `color: red;`;
  if (remainingChrs < 20) return `color: orange;`;
  if (remainingChrs < 40) return `color: orange;`;
}}
  box-sizing: border-box;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
`;
