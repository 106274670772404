import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { TwitterTextBox } from "./components/TwitterTextBox";
import { Provider as HooksForReduxProvider } from "hooks-for-redux";
import {
  twitterBlue,
  borderGray,
  barelyVisibleGrayBg,
} from "./components/colors";
import ThreadMarkerSelector from "./components/ThreadMarkerSelector";
import UserProfileBox from "./components/UserProfileBox";
import apiClient from "./api";
import { ConfigProvider as ReactAvatarProvider } from "react-avatar";
import {
  previewContentsToEditorContents,
  editorContentsToPreviewContents,
  useBirdsOrBears,
  setBirdsOrBears,
  BirdsOrBears,
  useEditorContents,
  setEditorContents,
  setPreviewContents,
  usePreviewContents,
  useThreadStyle,
  normalizeSentinels,
  INLINE_SPLIT_SENTINEL,
  SPLIT_SENTINEL,
} from "./store";
import styled from "styled-components";

function App() {
  const editorContents = useEditorContents();
  const previewContents = usePreviewContents();
  const birdsOrBears = useBirdsOrBears();
  const threadStyle = useThreadStyle();
  const inputRef: React.MutableRefObject<HTMLTextAreaElement> = useRef();
  const [cursorPos, setCursorPos] = useState(0);
  const [showSettingsPane, setShowSettingsPane] = useState(false);
  const [postStatusIndicator, setPostStatusIndicator] = useState(<span />);
  const placeholder = "Type your thread here.";

  // settings pane height toggle
  // 09/03/2020:
  // useEffect(() => {
  //   settingsPaneRef.current.style.height = showSettingsPane ? "30px" : "1px";
  // }, [showSettingsPane]);

  // cursor movement
  useEffect(() => {
    if (inputRef.current && cursorPos !== 0) {
      console.log("trying to reset cursor to ", cursorPos);
      inputRef.current.selectionStart = cursorPos;
      inputRef.current.selectionEnd = cursorPos;

      setCursorPos(0);
    }
  });

  const onEditorChanged = (ev: any) => {
    let contents = ev.target.value;
    let oldCursorPos = inputRef.current.selectionStart;
    let normalized = normalizeSentinels(contents);

    if (contents === normalized) {
      setEditorContents(contents);
    } else {
      let text = previewContentsToEditorContents(
        editorContentsToPreviewContents(normalized, threadStyle),
      );
      setCursorPos(oldCursorPos);
      if (inputRef?.current) {
        inputRef.current.selectionStart = cursorPos;
        inputRef.current.selectionEnd = cursorPos;
      }
      setEditorContents(text);
    }
    setPreviewContents(editorContentsToPreviewContents(contents, threadStyle));
  };

  const onPostThreadClicked = async () => {
    const removeSplitSentinelRegExp = new RegExp(INLINE_SPLIT_SENTINEL, "g");
    const tweets = previewContents.map((t) =>
      t.replace(removeSplitSentinelRegExp, "")
    );
    setPostStatusIndicator(<Spinning>⌛</Spinning>);
    let results: string[];
    try {
      // @ts-ignore
      results = await apiClient.postTweets(tweets);
    } catch (e) {
      setPostStatusIndicator(<span>⚠️</span>);
      console.log("⚠️  error!", e, results);
      alert(
        `Unable to post tweets. Twitter doesn't allow duplicate tweets. Perhaps you've already posted these tweets?\n\nError info:\n${e}\n`,
      );
      setTimeout(() => setPostStatusIndicator(<span />), 2500);
      return null;
    }
    console.log("Posted! results were: ", results);
    setPostStatusIndicator(<span>✅</span>);
    setTimeout(() => setPostStatusIndicator(<span />), 1000);
    return results;
  };

  const onShowSettings = (_: any) => {
    setShowSettingsPane(!showSettingsPane);
  };

  const postThreadButtonDisabled = previewContents.length === 0;

  return (
    <AppContainer>
      <Toolbar onShowSettings={onShowSettings} />
      <ColumnsContainer>
        <EditorsContainer>
          <BigEditor>
            <TextInput
              ref={inputRef}
              value={editorContents}
              onChange={onEditorChanged}
              placeholder={placeholder}
            />
          </BigEditor>
          <TweetsContainer>
            {previewContents.length === 0
              ? <TwitterTextBoxWrapper>
                <TwitterTextBox
                  placeholder={placeholder}
                  text=""
                />
              </TwitterTextBoxWrapper>
              : previewContents.map((text, idx) => (
                <TwitterTextBoxWrapper key={idx}>
                  <TwitterTextBox
                    idx={idx}
                    placeholder={placeholder}
                    text={text}
                  />
                </TwitterTextBoxWrapper>
              ))}
            <PostThreadButton
              clickable={postThreadButtonDisabled}
              onClick={postThreadButtonDisabled
                ? () => {
                  alert("🐦🐻 Please sign in to post threads 🐦🐻");
                }
                : onPostThreadClicked}
            >
              {postStatusIndicator} Post Thread
            </PostThreadButton>
          </TweetsContainer>
        </EditorsContainer>
      </ColumnsContainer>

      <TypeDashToSplitMsg>
        💡 <b>TIP:</b> Type <CodeSpan>{SPLIT_SENTINEL}</CodeSpan>
        to split tweets (<CodeSpan>{INLINE_SPLIT_SENTINEL}</CodeSpan>) !
      </TypeDashToSplitMsg>

      <SettingsPane visible={showSettingsPane}>
        {/*TODO: move this out to it's own component*/}
        <BirdOrBearToggle>
          <span>Birds, bears, or blank?</span>
          <span
            onClick={() => {
              setBirdsOrBears(BirdsOrBears.Birds);
              setShowSettingsPane(false);
            }}
          >
            {BirdsOrBears.Birds}
            <input
              checked={birdsOrBears === BirdsOrBears.Birds}
              name="BirdsOrBears"
              type="radio"
            />
          </span>

          <span
            onClick={() => {
              setBirdsOrBears(BirdsOrBears.Bears);
              setShowSettingsPane(false);
            }}
          >
            {BirdsOrBears.Bears}
            <input
              value={birdsOrBears}
              checked={birdsOrBears === BirdsOrBears.Bears}
              name="BirdsOrBears"
              type="radio"
            />
          </span>
          <span
            onClick={() => {
              setBirdsOrBears(BirdsOrBears.Blank);
              setShowSettingsPane(false);
            }}
          >
            😶<input
              value={birdsOrBears}
              checked={birdsOrBears === BirdsOrBears.Blank}
              name="BirdsOrBears"
              type="radio"
            />
          </span>
        </BirdOrBearToggle>
        {/*<ThreadMarkerSelector />*/}
      </SettingsPane>
    </AppContainer>
  );
}

const BirdOrBearToggle = styled.div`
  span {
    padding-right: 8px;
  }
  input {
    color: ${twitterBlue};
  }
`;

const Spinning = styled.div`
  display: inline-block;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
`;

const ToolbarRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const ToolbarLeft = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
`;
const ToolbarRight = styled.div`
  padding-right: 10px;
  box-sizing: border-box;
  background-color: ${barelyVisibleGrayBg};
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

const Toolbar = ({ onShowSettings }) =>
  <ToolbarRow>
    <ToolbarLeft>
      <SettingsButton onClick={onShowSettings}>
        <FontAwesomeIcon icon={faCog} />
      </SettingsButton>
    </ToolbarLeft>
    <ToolbarRight>
      <UserProfileBox />
    </ToolbarRight>
  </ToolbarRow>;

const ColumnsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  height: 100%;
  overflow-y: hidden;
`;

const SettingsButton = styled.div`
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
`;

interface PostThreadButtonProps {
  clickable: boolean;
}

const PostThreadButton = styled.button<PostThreadButtonProps>`
  ${({ clickable: disabled }) => disabled ? "opacity: .4;" : "opacity: 1;"}
  transition: width 0.15s, opacity 0.15s;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${twitterBlue};
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 15px; 
  height: 50px;
  &:focus {
    outline: none;
  }
`;

interface SettingsPaneProps {
  visible: boolean;
}
const SettingsPane = styled.div<SettingsPaneProps>`
  position: absolute;
  left: ${({ visible: v }) => v ? "30px" : "-500px"};
  overflow: hidden;
  transition: .15s;

  /* height: ${({ visible: v }) => v ? "30px" : "0"}; */
  /* opacity: ${({ visible: v }) => v ? 1 : 0}; */
  /* line-height: ${({ visible: v }) => v ? "30px" : "0"}; */
  line-height: 30px;

  justify-content: space-between;
  padding: 10px;
  padding-top: 0px;
  margin-bottom: 20px;
`;

const TypeDashToSplitMsg = ({ children }) => {
  return <ToolbarRow>
    <ToolbarLeft />
    <ToolbarRight>
      <div>
        {children}
      </div>
    </ToolbarRight>
  </ToolbarRow>;
};

const DebugOutput = ({ editorContents, previewContents }) => (<div
  style={{
    marginTop: "20px",
    "border": "1px solid black",
    "paddingLeft": "10px",
    "borderRadius": "5px",
  }}
>
  <pre>
    {`cr = "${INLINE_SPLIT_SENTINEL}";
e = ${JSON.stringify(editorContents, null, 2)};
p = ${JSON.stringify(previewContents, null, 2)}`}
  </pre>
</div>);

const TwitterTextBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 700px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

const DownArrow = styled.div`
  box-sizing: border-box;
  height: 15px;
  border-left: 1px solid ${borderGray};
`;

const CodeSpan = styled.span`
  background-color: #e8e8e8;
  border-radius: 2px;
  border: 1px solid ${borderGray};
  padding: 2px;
  margin: 2px;
  font-family: monospace;
`;

const TextInput = styled.textarea`
  border: 0px solid ${borderGray};
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 13pt;
  padding: 10px;
  border-radius: 0px 5px 5px 5px;
  width: 100%;
  height: 100%;
  resize: none;
  &:focus {
    outline: none;
  }
`;

const AppContainer = styled.div`

  /** TODO: figure out a cleaner way to make sure the container takes up the
   *  whole screen?  setting height: 100vh also works, altho height: 100% has
   *  no effect. **/

  /*  make sure container takes up whole screen */
  position: absolute; top: 0; bottom: 0; left: 0; right: 0;
  // height: 100vh;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const editorsTopMargin = 0;

const EditorsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  /* border: 1px solid black; */
`;

const BigEditor = styled.div`
  padding-top: ${editorsTopMargin}px;
  width: 100%;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
`;

const TweetsContainer = styled.div`
/*  border: 1px solid ${borderGray}; */
  // border-radius: 5px 0px 5px 5px;
  display: flex;
  padding-top: ${10 + editorsTopMargin}px;
  // border-left: 1px solid ${borderGray};
  background-color: ${barelyVisibleGrayBg};
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  overflow-x: hidden;
`;

export default function AppWrapper() {
  return (<HooksForReduxProvider>
    <ReactAvatarProvider
      avatarRedirectUrl="https://avatar-redirect.appspot.com"
    >
      <App />
    </ReactAvatarProvider>
  </HooksForReduxProvider>);
}
